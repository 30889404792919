<template>
  <layout :dividers="false">
    <template slot="header">
      {{ 'Custom period' }}
    </template>
    <v-form>
      <form-subheader title="From" />
      <form-date-picker key="fromDate" v-model="fromDate" label="Date" />
      <form-time-picker key="fromTime" v-model="fromTime" label="Time" />
      <form-subheader title="To" />
      <form-date-picker key="toDate" v-model="toDate" label="Date" />
      <form-time-picker key="toTime" v-model="toTime" label="Time" />
    </v-form>
    <template slot="footer">
      <v-spacer />
      <v-btn text @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn color="primary" text @click="submit">
        Play
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref } from '@vue/composition-api';
import { toISOStringWithTimezone } from '@/utils';
export default {
  name: 'CustomDateRangePopup',
  components: {
    Layout
  },
  props: {
    onSubmit: {
      type: Function,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const fromDate = ref(new Date().toISOString().substr(0, 10));
    const fromTime = ref('00:00');
    const toDate = ref(new Date().toISOString().substr(0, 10));
    const toTime = ref('00:00');

    const submit = () => {
      props.onSubmit({
        fromDate: toISOStringWithTimezone(
          new Date([fromDate.value, fromTime.value].join(' '))
        ),
        toDate: toISOStringWithTimezone(
          new Date([toDate.value, toTime.value].join(' '))
        )
      });
      emit('close');
    };

    return {
      fromDate,
      fromTime,
      toDate,
      toTime,
      submit
    };
  }
};
</script>

<style lang="sass"></style>
